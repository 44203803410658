import React, { Component } from 'react'

import './sign-in-out.scss'
import Button from 'react-bootstrap/Button';

const SignInOutButton = class extends Component {
  render() {
    if (!this.props.authenticated) {
      return (
        <Button
          variant="outline-primary"
          onClick={this.props.signin.bind(this)}
          className="sign-in-out p-3"
        >
          Teacher Login
        </Button>
      )
    } else if (this.props.authenticated) {
      return (
        <Button
          variant="outline-primary"
          onClick={this.props.signout.bind(this)}
          className="sign-in-out p-3"
        >
          Teacher Logout
        </Button>
      )
    }
  }
}

export default SignInOutButton
