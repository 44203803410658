import React, { Component } from 'react'
// import { StaticQuery, graphql } from 'gatsby'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'


const MyVerticallyCenteredModal = class extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Lesson 3.1: Mean Global Temp
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div className="embed-container">
          <iframe
            className="videoSetting"
            src="https://player.vimeo.com/video/322296039"
            frameBorder="0"
            // webkitAllowFullscreen
            // mozallowFullScreen
            allowFullScreen
          ></iframe>
         </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const AppThree =class extends Component {
  constructor(...args) {
    super(...args);

    this.state = { modalShow: false };
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false });

    return (
      <ButtonToolbar>
        <Button
          variant="primary"
          onClick={() => this.setState({ modalShow: true })}
        >
          Lesson 3.1: Mean Global Temp
        </Button>

        <MyVerticallyCenteredModal
          show={this.state.modalShow}
          onHide={modalClose}
        />
      </ButtonToolbar>
    );
  }
}

export default AppThree
