import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'


const MyVerticallyCenteredModal = class extends Component {
  render() {
    return (
      <Modal
        onHide={this.props.onHide}
        show={this.props.show}
        size="lg"
        aria-labelledby={`modal-${this.props.title}`}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={`modal-${this.props.title}`}>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="embed-container">
            <iframe
              title={this.props.title}
              className="videoSetting"
              src={this.props.url}
              frameBorder="0"
              // webkitAllowFullscreen
              // mozallowFullScreen
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const App = class extends Component {
  constructor(...args) {
    super(...args)

    this.state = {
      modal1Show: false,
      modal2Show: false,
      modal3Show: false,
      modal4Show: false
    }
  }

  modalClose = (modal_number) => this.setState({ [`modal${modal_number}Show`]: false })

  render() {
    const renderModalButton = <StaticQuery
      query={graphql`
        query navigationQuery2{
          allLessonOneVidJson {
            edges {
              node {
                title,
                url
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <ButtonToolbar>
            { data.allLessonOneVidJson.edges.map((edge, index) => {
              return (
                <React.Fragment key={`button-${index}`}>
                  <Button
                    className="mr-3 mb-3"
                    variant="primary"
                    onClick={() => this.setState({ [`modal${index + 1}Show`]: true })}
                  >
                    {edge.node.title}
                  </Button>
                  <MyVerticallyCenteredModal
                    show={this.state[`modal${index + 1}Show`]}
                    onHide={() => this.modalClose((index + 1).toString())}
                    url={edge.node.url}
                    title={edge.node.title}
                  />
                </React.Fragment>
              )
            })}
          </ButtonToolbar>
        )
      }}
    />
    return (
      <>
        {renderModalButton}
      </>
    )
  }
}

export default App
