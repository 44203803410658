import React, { Component } from "react"
import Layout from '../../components/layout/layout'
import SEO from "../../components/seo"
import App from "../../components/modal/modal"
import AppTwo from "../../components/modal/modal_two"
import AppThree from "../../components/modal/modal_three"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarTe from "../../components/layout/sidemenu/sidemenu_te"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import unit1Icon from "../../images/teacher-resources/unit_01_icon.svg"
import unit2Icon from "../../images/teacher-resources/unit_02_icon.svg"
import unit3Icon from "../../images/teacher-resources/unit_03_icon.svg"

import Auth from '../../auth/auth'
import SignInOutButton from "../../components/auth/sign-in-out-button"

import './teacher-materials.scss'


let auth
if (typeof window !== `undefined`) {
  auth = new Auth()
}

const TeacherMaterialsPage = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: undefined
    }
  }

  componentDidMount() {
    const user = auth.getUser()
    if (user) {
      this.setState({user_email: user.email, authenticated: auth.isAuthenticated()})
    } else {
      this.setState({ authenticated: auth.isAuthenticated() })
    }
  }

  signin = (e) => {
    e.preventDefault()
    auth.signin()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  signout = (e) => {
    e.preventDefault()
    auth.signout()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  render() {
    return (
      <Layout location={"/teacher-resources/"} >
        <SEO title="Carbon Connections - Teacher Materials" keywords={[`gatsby`, `application`, `react`]} />
        <Container fluid className="mb-5">
          <Row>
            <Col md="auto">
              <SideBarTe location={this.props.location.pathname} />
            </Col>
            <Col>
              <CCBreadcrumb
                pathname={this.props.location.pathname}
                title={'Teacher Materials'}
                replace={this.props.replace}
              />
              <Alert
                className="w-100"
                variant="warning"
              >
                BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
              </Alert>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header className="cardHeader">
                          <h1>
                            <span className="headerFontBlue">Teacher Materials</span>
                          </h1>
                        </Card.Header>
                        {auth && !auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <div className="d-flex justify-content-center">
                              <SignInOutButton
                                signin={this.signin}
                                signout={this.signout}
                                authenticated={this.state.authenticated}
                              />
                            </div>
                          </Card.Body>
                        }
                        {auth && auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <Row className="mb-4">
                              <Col>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <div className="d-flex mb-3">
                                      <div className="mr-3">
                                        <img
                                          className="img-fluid unitIcon"
                                          src={ unit1Icon }
                                          alt="Unit 1 icon"
                                        />
                                      </div>
                                      <div>
                                        <h2 style={{ lineHeight: '1' }}>Unit 1: <span className="headerFontBlue">Carbon &amp; Climate in the Past</span></h2>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <Row className="mb-4">
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Teacher Edition with Answers</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_1.1_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.1: Carbon Fizz with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_1.2_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.2: Carbon from the Past with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_1.3_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.3: Carbon Forcing with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_1.4_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.4: Global Connections with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_1.5_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.5: Core Connections with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Discussion Powerpoints</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_1.1_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.1: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_1.2_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.2: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_1.3_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.3: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_1.4_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.4: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_1.5_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 1.5: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                      <Col lg={6}>
                                        <Card className="h-100 mt-4">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Test Bank</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/test_bank/carbconn_unit_1_test_bank.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Unit 1 Test Bank&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                      <Col lg={6}>
                                        <Card className="h-100 mt-4">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Teacher Prep Videos</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <App />
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Col>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <div className="d-flex mt-3 mb-3">
                                      <div className="d-block mr-3">
                                        <img
                                          className="img-fluid unitIcon"
                                          src={ unit2Icon }
                                          alt="Unit 2 icon"
                                        />
                                      </div>
                                      <div>
                                        <h2 style={{ lineHeight: '1' }}>Unit 2: <span className="headerFontBlue">Carbon Now</span></h2>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <Row className="mb-4">
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Teacher Edition with Answers</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_2.1_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.1: Moving Carbon with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_2.2_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.2: Exploring Limits with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_2.3_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.3: The Breathing Biosphere with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_2.4_carbonconnections_teacher_pages_with-answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.4: Carbon Cycling with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_2.5_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.5: Earth takes a Breath with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Discussion Powerpoints</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_2.1_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.1: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_2.2_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.2: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_2.3_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.3: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_2.4_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.4: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_2.5_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 2.5: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Test Bank</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/test_bank/carbconn_unit_2_test_bank.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Unit 2 Test Bank&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Teacher Prep Videos</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <AppTwo />
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Col>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <div className="d-flex mt-3 mb-3">
                                      <div className="mr-3">
                                        <img
                                          className="img-fluid unitIcon"
                                          src={ unit3Icon }
                                          alt="Unit 3 icon"
                                        />
                                      </div>
                                      <div>
                                        <h2 style={{ lineHeight: '1' }}>Unit 3 :<span className="headerFontBlue">Carbon in the Future &amp; You</span></h2>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <Row className="mb-4">
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Teacher Edition with Answers</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_3.1_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.1: Your Temperature Connections with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_3.2_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.2: Testing Forcings with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_3.3_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.3: Future Forcings with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_3.4_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.4: It Starts at Home with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/teacher_ed_answers/l_3.5_carbonconnections_teacher_pages_with_answers_0.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.5: Core Carbon Claims with Answers&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Discussion Powerpoints</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_3.1_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.1: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_3.2_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.2: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_3.3_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.3: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="https://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_3.4_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.4: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                              <li className="mb-3">
                                                <a
                                                  href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/discussion_ppt/l_3.5_classroom_disc_questions.ppt"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Lesson 3.5: Classroom Discussion Questions&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Test Bank</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <a
                                                  href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/teacher_materials/test_bank/carbconn_unit_3_test_bank.pdf"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  Unit 3 Test Bank&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                                </a>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                      <Col lg={6}>
                                        <Card className="h-100">
                                          <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                            <h3>Teacher Prep Videos</h3>
                                          </Card.Header>
                                          <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                            <ul className="list-unstyled">
                                              <li className="mb-3">
                                                <AppThree />
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </Card.Body>
                        }
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default TeacherMaterialsPage
